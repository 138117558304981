<template>
	<edit-template @confirm="submit('form')" @cancel="cancel" :cancelRemind="false" confirmText="提交" cancelText="取消">
		
		<div class="form-body" style="padding-top: 70rem">
			<el-form ref="form" :model="formData" label-width="150rem" :rules="rules">
				<div style="padding-left: 36rem">
          <el-form-item label="考试名称" prop="exam_id">
            <el-select :popper-append-to-body="false" v-model="formData.exam_id" @change="examidChange">
              <el-option v-for="item in exam_name_arr" :key="item.value" :label="item.label" :value="item.value">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="总成绩本科线分数" prop="total_benco_score">
            <span>{{ benco_count }}</span>
          </el-form-item>
          <el-form-item label="总成绩一本线分数" prop="total_yiben_score">
            <span>{{ yiben_count }}</span>
          </el-form-item>
        </div>
				<div class="knowledge" v-for="(item, index) in formData.course" :key="index">
					<el-col :span="20" class="knowledge_box">
						<el-col :span="8">
							<el-form-item label="科目" label-width="100rem">
								<el-select :popper-append-to-body="false" v-model="formData.course[index].subject_id">
									<el-option v-for="item in subjects_arr" :key="item.value" :label="item.label" :value="item.value">
								</el-option></el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="本科线分数" label-width="100rem">
								<el-input-number v-model="formData.course[index].benco_score" :min="0" ref="number" :max="1000" :step="0.5" step-strictly="" :precision="1" @change="bencoScore(index)" @blur="benco_score_blur(item, index)" style="width: 150rem !important"></el-input-number>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="一本线分数" label-width="120rem">
								<el-input-number v-model="formData.course[index].yiben_score" :min="0" ref="number" :max="1000" :step="0.5" :precision="1" step-strictly="" style="width: 150rem !important" @change="yibenScore(index)" @blur="yiben_score_blur(item, index)"></el-input-number>
							</el-form-item>
						</el-col>
					</el-col>
					<!-- 关联知识点新增删除题目按钮 -->
					<el-col :span="4" class="add_knowledge">
						<el-button icon="el-icon-delete" circle="" @click="deleteKnowledge(index)"></el-button>
						<el-button icon="el-icon-plus" circle="" @click="addKnowledge" v-if="formData.course.length - 1 == index"></el-button>
					</el-col>
				</div>
			</el-form>
		</div>
		
	
</edit-template>
</template>

<script>
import { exam, subject, addExam } from './api.js'
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			// 考试科目
			subjects_arr: [],
			// 考试名称
			exam_name_arr: [],

			// 表单数据
			formData: {
				exam_id: '', // 考试名称
				// 总成绩本科线分数
				total_benco_score: 0,
				// 总成绩一本线分数
				total_yiben_score: 0,
				// 考试信息统计
				course: [
					{
						subject_id: '', //		科目ID
						benco_score: '', //	本科分数线
						yiben_score: '' //	一本分数线
					}
				]
			},
			// 表单验证规则
			rules: {
				exam_id: [{ required: true, message: '请选择考试名称', trigger: 'change' }],
				total_benco_score: [{ required: true, message: '请填写你的本科分数线', trigger: 'change' }],
				total_yiben_score: [{ required: true, message: '请填写你的一本分数线', trigger: 'change' }]
			}
		}
	},
	computed: {
		benco_count() {
			let benco_count = 0
			this.formData.course.forEach(item => {
				if (item.benco_count == null) {
					item.benco_count = 0
				}
				benco_count += item.benco_score
			})
			return this.$tools.formatNumber(benco_count)
		},
		yiben_count() {
			let yiben_count = 0
			this.formData.course.forEach(item => {
				if (item.yiben_count == null) {
					item.yiben_count = 0
				}
				yiben_count += item.yiben_score
			})
			return this.$tools.formatNumber(yiben_count)
		}
	},
	methods: {
		examidChange(val) {
			this.formData.course.forEach(item => {
				item.subject_id = ''
			})
			// 获取考试科目
			subject(val).then(res => {
				this.subjects_arr = JSON.parse(
					JSON.stringify(res.data.data)
						.replace(/id/g, 'value')
						.replace(/subject_name/g, 'label')
				)
			})
		},
		// 本科分数线变化
		bencoScore(index) {
			return
			this.formData.total_benco_score = 0
			for (let i = 0; i < this.formData.course.length; i++) {
				this.formData.total_benco_score += this.formData.course[i].benco_score
			}
		},
		// 一本分数线变化
		yibenScore(index) {
			return
			this.formData.total_yiben_score = 0
			for (let i = 0; i < this.formData.course.length; i++) {
				this.formData.total_yiben_score += this.formData.course[i].yiben_score
			}
		},
		// 删除知识点
		deleteKnowledge(index) {
			if (this.formData.course.length > 1) {
				this.formData.course.splice(index, 1)
			} else {
				this.$message.error('至少保留一条知识点')
			}
			this.yibenScore()
			this.bencoScore()
		},
		benco_score_blur(val) {
			if (val.benco_score === undefined) {
				val.benco_score = 0.0
			}
		},
		yiben_score_blur(val) {
			if (val.yiben_score === undefined) {
				val.yiben_score = 0.0
			}
		},
		// 添加知识点
		addKnowledge() {
			this.formData.course.push({
				subject_id: '', //		科目ID
				benco_score: '', //	本科分数线
				yiben_score: '' //	一本分数线
			})
			this.listenInput()
		},
		// 表单提交
		submit() {
			this.formData.total_benco_score = this.benco_count
			this.$refs.form.validate(valid => {
				if (valid) {
					let curseIds = {}
					for (let i = 0; i < this.formData.course.length; i++) {
						let item = this.formData.course[i]
						if (this.formData.course[i].subject_id == '') {
							this.$message.error('请输入完整的信息')
							return
						}
						if (this.formData.course[i].benco_score == 0 || this.formData.course[i].yiben_score == 0) {
							this.$message.error('请输入完整的信息')
							return
						}
						if (curseIds[item.subject_id] == null) curseIds[item.subject_id] = true
						else {
							this.$message.error('考试科目不可重复')
							return
						}
					}
					addExam(this.formData).then(() => {
						this.$store.commit('setPage', 1)
						this.$message.success('添加成功')
						this.$router.back()
					})
				} else {
					this.$message.error('请输入完整的信息。')
				}
			})
		},
		// 取消按钮
		cancel() {
			this.$confirm('是否取消新增?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.back()
			})
		},
		listenInput() {
			setTimeout(() => {
				let refs = this.$refs.number
				refs.forEach(item => {
					let input = item.$el.querySelector('input.el-input__inner')
					input.addEventListener('input', () => {
						let value = input.value
						input.value = value.replace(/[^\d.]/g, '')
					})
				})
			}, 20)
		}
	},
	created() {
		// 获取考试名称
		exam().then(res => {
			// this.exam_name_arr = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label'))
			this.exam_name_arr = JSON.parse(
				JSON.stringify(res.data.data)
					.replace(/exam_id/g, 'value')
					.replace(/exam_name/g, 'label')
			)
		})
		this.listenInput()
	}
}
</script>

<style lang="scss" scoped>
.knowledge {
	margin-top: 24rem;
	display: flex;
	justify-content: center;
	align-items: center;
	.knowledge_box {
		display: flex;
    padding-top: 12rem;
    height: 60rem;
    background-color: #f4f4f4;
	}
	.add_knowledge {
		display: flex;
		padding-left: 40rem;
	}
}
@media only screen and (max-width: 1500rem) {
	.knowledge {
		width: 1200rem !important;
	}
}
</style>
