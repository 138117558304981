import request from '@/utils/request'

/**
 * get
 * 获取考试名称
 */
// export const exam = () => {
//   return request({
//     method: 'get',
//     url: 'exam',
//   })
// }
export const exam = () => {
    return request({
        method: 'post',
        url: 'site/get-query-cond?need_exist_exam=0',
    })
}
/**
 * get
 * 获取考试科目
 */
export const subject = (exam_id) => {
    return request({
        method: 'get',
        url: 'site/exam-subject?exam_id=' + exam_id + '&subject=' + 1,
    })
}
/**
 * 分数统计设置删除
 */
export const deleteExam = (id) => {
    return request({
        method: 'delete',
        url: 'exam-count-set/' + id,
    })
}

/**
 * post
 * 分数统计设置新增
 */
export const addExam = (data) => {
    return request({
        method: 'post',
        url: 'exam-count-set/create',
        data
    })
}
/**
 * get
 * 分数统计设置详情
 */
export const setExam = (id) => {
    return request({
        method: 'get',
        url: 'exam-count-set/' + id,
    })
}

/**
 * put
 * 分数统计设置修改
 */

export const putExam = (id, data) => {
    return request({
        method: 'put',
        url: 'exam-count-set/' + id,
        data
    })
}